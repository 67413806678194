import { httpClient } from '../plugins/httpClient'
import type { IDownloadFileOptions } from '../models/utils/DownloadFile'

export async function downloadFile(options: IDownloadFileOptions) {
  const { headers, data, status } = await httpClient.get(options.url, {
    responseType: 'blob',
    params: options?.params || {}
  })

  if (!data || !headers || status !== 200) {
    return
  }

  const fileUrl = window.URL.createObjectURL(
    new Blob([data], { type: headers['content-type'] })
  )

  const fileLink = document.createElement('a')

  const fileName =
    options.name ||
    headers['content-disposition'] ||
    'filename=file'.split('filename=')[1].split(';')[0].replace(/"/g, '')

  fileLink.href = fileUrl

  if (!options?.isOpen) {
    fileLink.setAttribute('download', fileName)
  } else {
    fileLink.target = '_blank'
  }

  document.body.appendChild(fileLink)

  fileLink.click()
  fileLink.remove()

  window.URL.revokeObjectURL(fileUrl)
}
