import { defineStore } from 'pinia'
import { shallowRef } from 'vue'

import notificationApi from '~/api/notification.api'
import type { INotification } from '~/models/Notification'
import useWatchAuthenticated from '~common/composables/useWatchAuthenticated'
import useNotify from '~common/composables/notify/useNotify'
import { useI18n } from '~common/composables/useI18n'
import useCurrency from '~common/stores/currency'

export const useNotificationStore = defineStore('notification', () => {
  const { notifySuccess } = useNotify()
  const { t } = useI18n()
  const currency = useCurrency()

  const items = shallowRef<INotification[]>([])

  const getNotificationList = async () => {
    items.value = await notificationApi.fetchNotifications()
  }

  const getBonuses = () => {
    return items.value
      .filter(item => {
        return item.type === 'add_bonus' && !item.is_read
      })
      .flatMap(item => {
        return [item.message]
      })
      .reduce((result, item) => {
        return Number(result) + Number(item)
      }, 0)
  }

  const markBonusAsRead = async () => {
    await notificationApi.markBonusAsRead(items.value)
  }

  useWatchAuthenticated(async () => {
    await getNotificationList()

    const bonus = getBonuses()

    if (bonus) {
      notifySuccess(
        t('app.Bonus.message', {
          value: currency.formatBase(bonus, 'BTC'),
          currency: 'BTC'
        })
      )

      markBonusAsRead().then()
    }
  })

  return {
    items,
    getNotificationList,
    getBonuses,
    markBonusAsRead
  }
})
