import { defineStore } from 'pinia'
import { onBeforeUnmount, watch } from 'vue'
import websocketApi from '../api/websocket.api'

import type { IWebsocketMessage, TSubscribed } from '../models/Websocket'
import { useAuthStore } from '../stores/auth'

export const useWebsocketStore = defineStore('websocket', () => {
  const authStore = useAuthStore()

  const useChannel = (
    channel: string,
    callback: (data: IWebsocketMessage) => void,
    isPublic = false
  ) => {
    const subscribeData = subscribe(channel, callback, isPublic)

    onBeforeUnmount(() => {
      if (subscribeData) {
        unsubscribe(subscribeData)
      }
    })
  }

  const subscribe = (
    channel: string,
    callback: (data: IWebsocketMessage) => void,
    isPublic = false
  ): TSubscribed => {
    return websocketApi.subscribe(channel, callback, isPublic)
  }

  const unsubscribe = (subscribed: TSubscribed) => {
    if (!subscribed) {
      return
    }

    websocketApi.unsubscribe(subscribed.channel, subscribed.id)
  }

  watch(
    () => authStore.isAuthenticated,
    () => {
      websocketApi.setToken(authStore.session.access_token)
    },
    { immediate: true }
  )

  function connect() {
    if (authStore.session.access_token) {
      websocketApi.setToken(authStore.session.access_token)
    }

    websocketApi.connect()
  }

  if (process.client) {
    window.addEventListener('online', connect)
    connect()
  }

  return {
    useChannel,
    subscribe,
    unsubscribe
  }
})
