export function lastSymbToLower(str: string) {
  return String(str.slice(0, -1)) + String(str.slice(-1).toLocaleLowerCase())
}

export function toBase64(s?: string) {
  if (!s) {
    return
  }

  const bytes = new TextEncoder().encode(s)

  const binString = Array.from(bytes, x => String.fromCodePoint(x)).join('')

  return btoa(binString)
}

export async function SHA(text: string, algorithm = 'SHA-256') {
  const encoder = new TextEncoder()
  const data = encoder.encode(text)
  const hashBuffer = await crypto.subtle.digest(algorithm, data)
  const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array

  // convert bytes to hex string
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
}
