<route>
{ meta: { guideName: 'home', header: 'full' } }
</route>

<template>
  <component :is="$screen.lt.md ? indexMobile : indexDesktop" />
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

defineOptions({
  name: 'IndexRoute'
})

/* ------------------------------------------------------------------------------------------------------------------ */

const indexDesktop = defineAsyncComponent(
  () => import('~/components/pages/desktop/index.vue')
)

const indexMobile = defineAsyncComponent(
  () => import('~/components/pages/mobile/index.vue')
)
</script>
