import { type Router } from 'vue-router'
import { initYandexMetrika } from './yandex-metrika/index'
import { type Plugin } from 'vue'

const mode = import.meta.env.MODE
const enabled = mode === 'stage' || mode === 'production'
const fakeEnv = enabled ? 'production' : undefined

export default (router: Router) =>
  [
    initYandexMetrika,
    {
      id: import.meta.env.PUBLIC_YANDEX_METRIKA_ID,
      router,
      env: fakeEnv,
      debug: false,
      options: {
        defer: true,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true
      }
    }
  ] as [Plugin, object]
