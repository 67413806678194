import { defineStore } from 'pinia'
import type {
  TCurrency,
  IPair,
  IRawPair,
  TCurrencyPair
} from '../models/Currency'
import { computed, ref } from 'vue'
import { useWebsocketStore } from '../stores/websocket'
import type { TSubscribed } from '../api/websocket.api'
import Big from 'big.js'

import { lastSymbToLower } from '../utils/strings'
import { isSubCurrency } from '../utils/currency'
import useWatchAuthenticated from '../composables/useWatchAuthenticated'

interface ICurrencyListWS {
  pair: TCurrencyPair
  showOnPanel: boolean
  precision: number
}

const CURRENCY_PAIRS: ICurrencyListWS[] = [
  { pair: 'BTC/RUB.R', showOnPanel: true, precision: 0 },
  { pair: 'ETH/RUB.R', showOnPanel: true, precision: 0 },
  { pair: 'BTC/USD.R', showOnPanel: true, precision: 0 },
  { pair: 'ETH/USD.R', showOnPanel: true, precision: 0 },
  { pair: 'USDT/RUB.R', showOnPanel: true, precision: 2 },
  { pair: 'USD.R/RUB.R', showOnPanel: false, precision: 2 }
]

const exchangeRates = defineStore('exchangeRates', () => {
  let rateSubscribes: TSubscribed[]

  const { subscribe, unsubscribe } = useWebsocketStore()

  const preDefineRates: IPair[] = []

  useWatchAuthenticated(subscribeCoursePairs, unsubscribeCoursePairs)

  CURRENCY_PAIRS.forEach(item => {
    const [base, quote] = item.pair.split('/')

    preDefineRates.push({
      market: item.pair,
      base: base as TCurrency,
      quote: quote as TCurrency,
      base_formated: isSubCurrency(base as TCurrency)
        ? lastSymbToLower(base)
        : base,
      quote_formated: isSubCurrency(quote as TCurrency)
        ? lastSymbToLower(quote)
        : quote,
      price: 0,
      price_diff: 0,
      relative_diff: 0,
      showOnPanel: item.showOnPanel,
      format_precision: item.precision
    })
  })

  const ratesPairs = ref<IPair[]>(preDefineRates)

  const ratesPairsVisible = computed(() => {
    return ratesPairs.value.filter(item => item.showOnPanel)
  })

  function subscribeCoursePairs() {
    rateSubscribes = CURRENCY_PAIRS.map((item, index) => {
      return subscribe(
        `rate:${item.pair}`,
        ({ data }) => {
          const rawPair = data as IRawPair
          const ratesPair = ratesPairs.value[index]

          ratesPair.price = +rawPair.price
          ratesPair.price_diff = +rawPair.price_diff

          ratesPair.relative_diff =
            Math.round((ratesPair.price_diff / ratesPair.price) * 10000) / 100

          if (Number.isNaN(ratesPair.relative_diff)) {
            ratesPair.relative_diff = 0
          }
        },
        true
      )
    })
  }

  function unsubscribeCoursePairs() {
    if (!rateSubscribes || !rateSubscribes.length) {
      return
    }

    rateSubscribes.forEach(subscrib => {
      unsubscribe(subscrib)
    })
  }

  function exchange(
    currencySymbol: TCurrency | undefined,
    value: number | null | undefined
  ) {
    if (value === null || value === undefined || !currencySymbol) {
      return 0
    }

    if (currencySymbol === 'RUB.R') {
      return value
    }

    // Курс AED бэк не будет присылать. Будем считать, что 1 USDT = 3.67 AED. Курс USDT/RUB у нас есть
    if (currencySymbol === 'AED') {
      value = value / 3.67 // сколько USDT в value (AED)
      currencySymbol = 'USDT' // будем искать курс USDT/RUB
    }

    const currencyRate = ratesPairs.value.find(rate => {
      return rate.base === currencySymbol
    })

    if (!currencyRate) {
      return 0
    }

    return new Big(currencyRate.price || 0).times(value).toNumber()
  }

  return {
    ratesPairs,
    ratesPairsVisible,
    exchange
  }
})

export default exchangeRates
