<template>
  <img
    :src="`${buccketUrl}/wl/${hostname}/logos/header.svg`"
    v-bind="$attrs"
    alt="Header logo"
  />
</template>

<script setup lang="ts">
import { wlHostname } from '../../utils/wl'

const buccketUrl = import.meta.env.PUBLIC_BUCCKET_URL
const hostname = wlHostname(window.location.hostname)
</script>
