<template>
  <svg class="icon">
    <title v-if="title">{{ title }}</title>
    <use :xlink:href="icon"></use>
  </svg>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{ name?: string; path?: string; title?: string }>()

const icon = computed(() => {
  if (!props.path && !props.name) {
    return
  }

  if (props.path) {
    return props.path
  }

  if (props.name && props.name.startsWith('com:')) {
    const name = props.name.substring(4)

    return `/common-icons.svg#${name}`
  }

  return `/icons.svg#${props.name}`
})
</script>
