import * as Sentry from '@sentry/vue'
import { type App } from 'vue'
import { type Router } from 'vue-router'

import { AxiosError } from 'axios'
import type { EventHint, ErrorEvent } from '@sentry/vue'

export function sentryInit(app: App, router: Router) {
  if (
    import.meta.env.MODE === 'development' ||
    import.meta.env.MODE === 'qa01' ||
    !import.meta.env.PUBLIC_SENTRY_DSN
  ) {
    return
  }

  Sentry.init({
    app,
    dsn: import.meta.env.PUBLIC_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({
        router
      }),
      Sentry.replayIntegration()
    ],
    attachStacktrace: true,
    environment: import.meta.env.MODE,
    release: import.meta.env.PUBLIC_VERSION,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: import.meta.env.PUBLIC_SENTRY_TRACES_SAMPLE_RATE,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: import.meta.env
      .PUBLIC_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
    replaysOnErrorSampleRate: import.meta.env
      .PUBLIC_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,

    ignoreErrors: [
      /.*token has expired$/,
      'Unauthorized',
      'Unknown server error',
      'InvalidStateError',
      'NS_ERROR_NOT_INITIALIZED'
    ],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ],
    beforeSend(event: ErrorEvent, hint: EventHint) {
      if (hint.originalException instanceof AxiosError) {
        const code = hint.originalException.code
        const status = hint.originalException.response?.status

        if (status === 401) {
          return null
        }

        switch (code) {
          case 'WrongCredentialsError':
          case 'WrongEmailVerificationCodeError':
          case 'Need2FA':
          case 'Invalid2FACode':
          case 'NeedCompleteKYC':
          case 'NeedCompleteKYCKgz':
          case 'WithdrawalIsTemporarilyBlocked':
          case 'NotEnoughMoneyOnBalance':
          case 'OperationTemporaryUnavailable':
          case 'UserAlreadyExists':
          case 'UserNotConfirmedError':
          case 'UserNotFound':
          case 'NotEnoughMoneyOnLendings':
          case 'InviteNotFound':
          case 'UnknownPictureKind':
          case 'InsufficientBalance':
          case 'InvalidWithdrawalAddress':
          case 'RepresentativeNotFound':
          case 'ExpiredEmailVerificationCodeError':
          case 'PasswordResetIncompleteError':
          case 'PasswordResetExpiredCodeError':
          case 'UserIsBlocked':
          case 'BorrowingPledgeAmountGreaterThenBalance':
          case 'UnknownUser':
          case 'P2PContainActiveDeals':
          case 'NicknameAlreadyInUse':
          case 'NeedFillNickname':
          case 'WrongNickname':
          case 'LargeFileSize':
          case 'NeedActivateTelegramNotifications':
          case 'YouHaveActiveP2PRequests':
          case 'ReferralLinkAlreadyExists':
          case 'BruteForceDefense':
          case 'AlreadyUsed2FACode':
          case 'OperationLocked':
          case 'CanNotFindRateInvestmentAsset':
          case 'TooSmallInvestmentCost':
          case 'ApplicationTemporaryUnavailable':
          case 'FinanceBankAccountNotFound':
          case 'BlockingAmountMoreThanUserAvailable':
          case 'UnblockingAmountGreaterThanBlocking':
          case 'WriteOffAmountGreaterThanBlocked':
            return null
        }
      }

      return event
    }
  })
}
