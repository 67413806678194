import type { TCurrency } from '../models/Currency'

export function isUsdFamily(symbol: TCurrency) {
  return ['USD.R', 'USD.K'].includes(symbol)
}

export function isRubFamily(symbol: TCurrency) {
  return ['RUB.R', 'RUB.K'].includes(symbol)
}

export function symbolToURLsymbol(symbol: TCurrency) {
  return symbol.replace('.', '_')
}

export function isSubCurrency(symbol: TCurrency) {
  return isUsdFamily(symbol) || isRubFamily(symbol)
}
