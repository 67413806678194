import { defineStore } from 'pinia'

import useCurrency from '../composables/useCurrency'
import currencyApi from '../api/currency.api'

import type { ICurrencyCommon, ICurrencyAdmin } from '../models/Currency'

export default defineStore('user-currency', () => {
  if (__IS_ADMIN_PANEL__) {
    return useCurrency<ICurrencyAdmin>(currencyApi.fetchAdminCurrencies)
  }

  const fetcher = __IS_APP__
    ? currencyApi.fetchUserCurrencies
    : currencyApi.fetchPublicCurrencies

  return useCurrency<ICurrencyCommon>(fetcher)
})
