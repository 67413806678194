import { httpClient } from '~common/plugins/httpClient'
import type {
  IPaginationList,
  IPaginationPayload
} from '~common/models/Pagination'
import type {
  ICreateSwiftWithdrawPayload,
  IWithdrawalSwiftUploadDocResponse,
  IWithdrawalSwiftTemplateDeleteResponse,
  IWithdrawalSwiftTemplate,
  IGetSwiftPayload,
  ISwiftListResponse,
  IPatchSwift,
  ISwiftItem,
  ISwiftProviderPayment,
  IFetchSwiftPaymentsParams,
  IUpdateProviderPaymentPayload,
  IExportProviderRequisitesPayload
} from '~/models/Swift'
import type { IData } from '~/common/src/models/Api'
import { downloadFile } from '~/common/src/utils/downloadFile'
import { crossPlatformDownloadFile } from '~/utils/cross-platform/downloadFile'

export default {
  async createSwiftWithdraw(payload: ICreateSwiftWithdrawPayload) {
    return await httpClient.post<ISwiftItem>('/withdrawals/swift', payload)
  },

  async getSwift(id: number) {
    const result = await httpClient.get<ISwiftItem>('/withdrawals/swift/' + id)

    return result.data
  },

  async getSwiftList(payload: IGetSwiftPayload) {
    const result = await httpClient.get<ISwiftListResponse>(
      '/withdrawals/swift',
      {
        params: payload
      }
    )

    return result.data
  },

  async updateSwift(id: number | undefined, payload: IPatchSwift) {
    if (id === undefined) {
      console.error('You must specify the sfift id')

      return
    }

    return await httpClient.patch<ISwiftItem>(
      '/withdrawals/swift/' + id,
      payload
    )
  },

  async uploadDoc(swiftId: number, files: FileList | null) {
    if (!files) {
      return
    }

    const file = files.length > 0 && files[0]

    if (!file) {
      return
    }

    const formData = new FormData()

    formData.append('swfit.doc', file, file.name)

    return await httpClient<IWithdrawalSwiftUploadDocResponse>({
      method: 'post',
      url: `/files/withdrawals/swift/${swiftId}/payment-document`,
      data: formData
    })
  },

  async getWithdrawalSwiftTemplates(
    params: { currency_id?: number } & IPaginationPayload
  ) {
    const result = await httpClient.get<
      IPaginationList<IWithdrawalSwiftTemplate>
    >('/withdrawals/swift-templates', { params })

    return result.data
  },

  async deleteWithdrawalSwiftTemplate(id: number) {
    const result =
      await httpClient.delete<IWithdrawalSwiftTemplateDeleteResponse>(
        `/withdrawals/swift-templates/${id}`
      )

    return result.data
  },

  async fetchProviderPayments(params: IFetchSwiftPaymentsParams) {
    const result = await httpClient.get<IData<ISwiftProviderPayment>>(
      '/provider/withdrawals/swift',
      { params }
    )

    return result.data
  },

  async updateProviderPayment(payload: IUpdateProviderPaymentPayload) {
    const result = await httpClient.patch<ISwiftProviderPayment>(
      `/provider/withdrawals/swift/${payload.id}`,
      payload
    )

    return result.data
  },

  async uploadProviderPaymentDoc(id: number, files: FileList | null) {
    const file = files?.[0]

    if (!file) return

    const formData = new FormData()

    formData.append(`payment.doc.${id}`, file, file.name)

    const result = await httpClient({
      method: 'post',
      url: `/provider/withdrawals/swift/${id}/swift-file`,
      data: formData
    })

    return { result, filename: file.name }
  },

  async fetchProviderPaymentDoc(payload: { id: number; filename: string }) {
    await downloadFile({
      url: `provider/withdrawals/swift/${payload.id}/swift-file`,
      name: payload.filename
    })
  },

  async exportProviderRequisites({
    pk,
    format
  }: IExportProviderRequisitesPayload) {
    await crossPlatformDownloadFile({
      url: `provider/withdrawals/swift/${pk}/export/${format}`,
      name:
        format === 'pdf'
          ? `swift-${pk}-requisites.pdf`
          : `swift-${pk}-requisites.csv`
    })
  }
}
