/* eslint-disable @typescript-eslint/no-explicit-any */
import { type ComponentPublicInstance, unref } from 'vue'

export function offset(el: Window | HTMLElement) {
  if (el === window) {
    return { top: 0, left: 0 }
  }

  el = el as HTMLElement

  const { top, left } = el.getBoundingClientRect()

  return { top, left }
}

export function style(el: HTMLElement, property: string) {
  return window.getComputedStyle(el).getPropertyValue(property)
}

export function height(el: Window | HTMLElement) {
  return el === window
    ? window.innerHeight
    : (el as HTMLElement).getBoundingClientRect().height
}

export function width(el: Window | HTMLElement | Element) {
  return el === window
    ? window.innerWidth
    : (el as Element).getBoundingClientRect().width
}

export function css(element: HTMLElement, css: Partial<CSSStyleDeclaration>) {
  const style = element.style

  for (const prop in css) {
    style[prop] = css[prop] || ''
  }
}

export function cssBatch(elements: HTMLElement[], style: CSSStyleDeclaration) {
  elements.forEach(el => css(el, style))
}

export function ready(fn: () => any) {
  if (typeof fn !== 'function') {
    return
  }

  if (document.readyState !== 'loading') {
    return fn()
  }

  document.addEventListener('DOMContentLoaded', fn, false)
}

export function getElement(
  el: HTMLElement | ComponentPublicInstance | string | undefined
) {
  if (el === void 0 || el === null) {
    return void 0
  }

  if (typeof el === 'string') {
    try {
      return document.querySelector(el) || void 0
    } catch (err) {
      return void 0
    }
  }

  const target = unref(el) as ComponentPublicInstance

  if (target) {
    return target.$el || target
  }
}

export function childHasFocus(el: HTMLElement, focusedEl: HTMLElement) {
  if (el === void 0 || el === null || el.contains(focusedEl)) {
    return true
  }

  for (
    let next = el.nextElementSibling;
    next !== null;
    next = next.nextElementSibling
  ) {
    if (next.contains(focusedEl)) {
      return true
    }
  }

  return false
}

export default {
  offset,
  style,
  height,
  width,
  css,
  cssBatch,
  ready
}
