import { useModals } from './useModals'
import CaptchaModal from '../components/auth/CaptchaModal.vue'

export default () => {
  const modals = useModals()

  function renderCaptcha() {
    return new Promise<string>(resolve => {
      modals.show(CaptchaModal, {
        name: 'CaptchaModal',
        compListeners: {
          onSuccess(wafToken: string) {
            resolve(wafToken)
          }
        }
      })
    })
  }

  return {
    renderCaptcha
  }
}
