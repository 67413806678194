export interface IGetContactInfoResponse {
  client_name: string | null
  email: string | null
  phone: string | null
  description: string | null
}

export interface ISetContactInfoPayload {
  name: string
  email: string
  phone: string
  description: string
}

const jivoDestroy = () => {
  if (!('jivo_destroy' in window) || typeof window.jivo_destroy !== 'function')
    return

  window.jivo_destroy()
}

const setContactInfo = (payload: Partial<ISetContactInfoPayload>) => {
  if (!window.jivo_api) return

  window.jivo_api.setContactInfo(payload)
}

const getContactInfo = () => {
  if (!window.jivo_api) return

  const res = window.jivo_api.getContactInfo()

  return res as IGetContactInfoResponse
}

export default {
  initVidget(widgetId?: string) {
    if (!widgetId) return

    jivoDestroy()

    const jivoScript = document.createElement('script')

    jivoScript.src = `//code.jivo.ru/widget/${widgetId}`
    jivoScript.async = true

    document.head.appendChild(jivoScript)
  },

  open() {
    if (!window.jivo_api) return

    window.jivo_api.open()
  },

  close() {
    if (!window.jivo_api) return

    window.jivo_api.close()
  },

  setUserToken(token: string) {
    if (!window.jivo_api) return

    const res = window.jivo_api.setUserToken(token)

    return res
  },

  updateContactInfo(payload: Partial<ISetContactInfoPayload>) {
    const info = getContactInfo()

    setContactInfo({
      name: info?.client_name || payload.name,
      phone: info?.phone || payload.phone,
      email: info?.email || payload.email,
      description: info?.description || payload.description
    })
  }
}
