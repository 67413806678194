import { httpClient } from '~common/plugins/httpClient'
import { client } from '~common/plugins/platform'
import { Filesystem } from '@capacitor/filesystem'
import { Directory } from '@capacitor/filesystem/dist/esm/definitions'
import { nanoid } from 'nanoid'
import type { FileOpenerOptions } from '@capacitor-community/file-opener'
import { FileOpener } from '@capacitor-community/file-opener'
import type {
  HttpHeaders,
  HttpParams
} from '@capacitor/core/types/core-plugins'
import type { IDownloadFileOptions } from '~common/models/utils/DownloadFile'
import { downloadFile } from '~common/utils/downloadFile'

export async function crossPlatformDownloadFile(options: IDownloadFileOptions) {
  if (client.is.capacitor) {
    const { path } = await Filesystem.downloadFile({
      responseType: 'blob',
      params: options?.params as HttpParams,
      url: `${httpClient.defaults.baseURL}${options.url}`,
      directory: Directory.External,
      path: `${options.name || nanoid()}`,
      headers: {
        ...(httpClient.defaults.headers.common as HttpHeaders)
      },
      recursive: true
    })

    if (path) {
      const fileOpenerOptions: FileOpenerOptions = {
        filePath: path,
        openWithDefault: true
      }

      FileOpener.open(fileOpenerOptions).then()
    }

    return
  }

  await downloadFile(options)
}
