/* istanbul ignore file */
import type { App } from 'vue'
import {
  updateConfig,
  checkConfig,
  loadScript,
  createMetrika,
  startTracking
} from './helpers'
import type { Config } from './types'
import { EmptyYaMetrika, type YaMetrika } from './types'

let _metrikaInstance: YaMetrika | null = null
let hasLoading = true

export function initYandexMetrika(app: App, options: Config) {
  return new Promise((resolve, reject) => {
    app.config.globalProperties.$yandexMetrika = new EmptyYaMetrika()
    updateConfig(options)
    checkConfig()

    loadScript(
      () => {
        _metrikaInstance = createMetrika(app)
        startTracking(_metrikaInstance)
        hasLoading = false
        resolve(undefined)
      },
      () => {
        hasLoading = false
        reject()
      },
      options.scriptSrc || 'https://mc.yandex.ru/metrika/tag.js'
    )
  })
}

export function useYandexMetrika() {
  if (_metrikaInstance) {
    return _metrikaInstance
  } else {
    if (!hasLoading) {
      console.error('Yandex metrika has not been initialized')
    }

    _metrikaInstance = new EmptyYaMetrika()

    return _metrikaInstance
  }
}
