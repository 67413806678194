import { httpClient } from '~common/plugins/httpClient'
import type { INotification } from '~/models/Notification'

export default {
  async fetchNotifications() {
    const result = await httpClient.get<INotification[]>('/notifications')

    return result.data
  },

  async markBonusAsRead(notifications: INotification[]) {
    const lastId = Math.max(
      ...notifications
        .filter(item => {
          return item.type === 'add_bonus' && !item.is_read
        })
        .flatMap(item => {
          return [Number(item.id)]
        })
    )

    notifications.forEach(item => {
      if (item.type === 'add_bonus' && !item.is_read) {
        item.is_read = true
      }
    })

    await httpClient.post('/notifications/mark_as_read', { id: lastId })
  }
}
