<template>
  <AppModal
    ref="modal"
    :width="width"
    :height="height"
    :content-class="contentClass"
    :close-if-change-route="closeIfChangeRoute"
  >
    <div class="flex flex-col gap-3 md:gap-8">
      <div
        class="flex flex-col bg-white"
        :class="{ 'sticky top-0 z-20 pb-5': fixedHeader }"
      >
        <div
          class="flex flex-row gap-1 md:gap-2 h2 mb-0"
          :class="{ 'justify-center': captionCenter }"
        >
          <span
            v-if="captionPrefix"
            class="text-gray"
            >{{ captionPrefix }}</span
          >
          <template v-if="caption">
            {{ caption }}
          </template>
        </div>

        <div
          v-if="subCaption"
          class="flex items-center gap-1 text-small text-gray md:text-base"
        >
          <slot name="subCaptionPrefix" />
          {{ subCaption }}
        </div>
      </div>

      <div
        class="flex flex-col gap-2 md:gap-5"
        :class="{ '-mt-5': fixedHeader, '-mb-8': fixedFooter }"
      >
        <slot />
      </div>

      <div
        class="flex justify-between gap-3 md:gap-4 bg-white"
        :class="{ 'sticky bottom-0 z-20 pt-8': fixedFooter }"
        @click="onActionBlockClick"
      >
        <slot name="actions">
          <AppButton
            :view="okTextView"
            v-if="okText"
            :size="$screen.gt.sm ? 'big' : 'normal'"
            :text="okText"
            @click="onOk"
            :loading="isSubmitting"
            :disabled="okDisabled"
          />
          <AppButton
            :view="cancelView"
            v-if="cancelText"
            :size="$screen.gt.sm ? 'big' : 'normal'"
            :text="cancelText"
            @click="onCancel"
            :disabled="cancelDisabled"
            :loading="cancelSubmitting"
          />
        </slot>
      </div>
    </div>
  </AppModal>
</template>

<script setup lang="ts">
import AppModal from '../components/AppModal.vue'
import { ref, toRef, watch } from 'vue'
import AppButton from '../components/AppButton.vue'
import type { IModalDesktop, IModalEmits } from '../models/Modal'

type ModalInstance = InstanceType<typeof AppModal>

const props = withDefaults(defineProps<IModalDesktop>(), {
  width: '30.125rem',
  canClose: true,
  cancelView: 'outline'
})

const emit = defineEmits<IModalEmits>()

/* ------------------------------------------------------------------------------------------------------------------ */

const modal = ref<ModalInstance>()

function onActionBlockClick() {
  tryClose(props.closeOnActionClick, props.isSubmitting)
}

watch(
  [toRef(props, 'closeOnActionClick'), toRef(props, 'isSubmitting')],
  ([closeOnActionClick, isSubmitting]) => {
    tryClose(closeOnActionClick, isSubmitting)
  }
)

function tryClose(closeOnActionClick: boolean, isSubmitting: boolean) {
  if (closeOnActionClick && !isSubmitting) {
    modal.value?.close()
  }
}

function onOk() {
  emit('onOk')
}

function onCancel() {
  emit('onCancel')
}

defineExpose({
  close: () => modal.value?.close()
})
</script>
