/* eslint-disable no-useless-escape */
import type { App } from 'vue'

export interface IBrowser {
  name: string
  platform?: string
  version?: string
  versionNumber?: number
  mobile: boolean
  edge?: boolean
  chrome?: boolean
  firefox?: boolean
  mac?: boolean
  ios?: boolean
  bb?: boolean
  android?: boolean
  kindle?: boolean
  opera?: boolean
  opr?: boolean
  safari?: boolean
  edgeChromium?: boolean
  edg?: boolean
  blackberry?: boolean
  silk?: boolean
  ipod?: boolean
  ipad?: boolean
  iphone?: boolean
  electron?: boolean
  bex?: boolean
  edga?: boolean
  edgios?: boolean
  fxios?: boolean
  crios?: boolean
  nativeMobile?: boolean
  nativeMobileWrapper?: string
  capacitor?: boolean
  cordova?: boolean
  vivaldi?: boolean
  desktop?: boolean
  webkit?: boolean
  winphone?: boolean
  playbook?: boolean
  'windows phone'?: boolean
  [index: string]: string | boolean | undefined | number
}

export interface IIosCorrection {
  is: IBrowser
}

export interface IHas {
  touch: boolean
  webStorage: boolean
}

export interface IWithin {
  iframe: boolean
}

export interface IPlatform {
  userAgent: string
  is: IBrowser
  has: IHas
  within: IWithin
}

const userAgent = process.client ? navigator.userAgent || window.opera : ''

const hasTouch = process.client
  ? 'ontouchstart' in window || window.navigator.maxTouchPoints > 0
  : false

export const iosEmulated = false
export let iosCorrection: IIosCorrection

function getMatch(userAgent: string, platformMatch: RegExpExecArray | []) {
  const match =
    /(edg|edge|edga|edgios)\/([\w.]+)/.exec(userAgent) ||
    /(opr)[\/]([\w.]+)/.exec(userAgent) ||
    /(vivaldi)[\/]([\w.]+)/.exec(userAgent) ||
    /(chrome|crios)[\/]([\w.]+)/.exec(userAgent) ||
    /(version)(applewebkit)[\/]([\w.]+).*(safari)[\/]([\w.]+)/.exec(
      userAgent
    ) ||
    /(webkit)[\/]([\w.]+).*(version)[\/]([\w.]+).*(safari)[\/]([\w.]+)/.exec(
      userAgent
    ) ||
    /(firefox|fxios)[\/]([\w.]+)/.exec(userAgent) ||
    /(webkit)[\/]([\w.]+)/.exec(userAgent) ||
    /(opera)(?:.*version|)[\/]([\w.]+)/.exec(userAgent) ||
    []

  return {
    browser: match[5] || match[3] || match[1] || '',
    version: match[2] || match[4] || '0',
    versionNumber: match[4] || match[2] || '0',
    platform: platformMatch[0] || ''
  }
}

function getPlatformMatch(userAgent: string): RegExpExecArray | [] {
  return (
    /(ipad)/.exec(userAgent) ||
    /(ipod)/.exec(userAgent) ||
    /(windows phone)/.exec(userAgent) ||
    /(iphone)/.exec(userAgent) ||
    /(kindle)/.exec(userAgent) ||
    /(silk)/.exec(userAgent) ||
    /(android)/.exec(userAgent) ||
    /(win)/.exec(userAgent) ||
    /(mac)/.exec(userAgent) ||
    /(linux)/.exec(userAgent) ||
    /(cros)/.exec(userAgent) ||
    // Remove BlackBerry detection. BlackBerry OS, BlackBerry 10, and BlackBerry PlayBook OS
    // is officially dead as of January 4, 2022 (https://www.blackberry.com/us/en/support/devices/end-of-life)
    /(playbook)/.exec(userAgent) ||
    /(bb)/.exec(userAgent) ||
    /(blackberry)/.exec(userAgent) ||
    []
  )
}

function applyIosCorrection(is: IBrowser) {
  iosCorrection = { is: { ...is } }

  delete is.mac
  delete is.desktop

  const platform =
    Math.min(window.innerHeight, window.innerWidth) > 414 ? 'ipad' : 'iphone'

  Object.assign(is, {
    mobile: true,
    ios: true,
    platform,
    [platform]: true
  })
}

function getPlatform(UA: string) {
  const userAgent = UA.toLowerCase(),
    platformMatch = getPlatformMatch(userAgent),
    matched = getMatch(userAgent, platformMatch),
    browser: IBrowser = {
      name: '',
      mobile: false,
      desktop: false
    }

  if (matched.browser) {
    browser[matched.browser] = true
    browser.version = matched.version
    browser.versionNumber = parseInt(matched.versionNumber, 10)
  }

  if (matched.platform) {
    browser[matched.platform] = true
  }

  const knownMobiles =
    browser.android ||
    browser.ios ||
    browser.bb ||
    browser.blackberry ||
    browser.ipad ||
    browser.iphone ||
    browser.ipod ||
    browser.kindle ||
    browser.playbook ||
    browser.silk ||
    browser['windows phone']

  // These are all considered mobile platforms, meaning they run a mobile browser
  if (
    knownMobiles === true ||
    userAgent.indexOf('mobile') !== -1 ||
    (navigator && navigator.userAgentData && navigator.userAgentData.mobile) // https://developer.mozilla.org/en-US/docs/Web/API/User-Agent_Client_Hints_API
  ) {
    browser.mobile = true

    if (browser.edga || browser.edgios) {
      browser.edge = true
      matched.browser = 'edge'
    } else if (browser.crios) {
      browser.chrome = true
      matched.browser = 'chrome'
    } else if (browser.fxios) {
      browser.firefox = true
      matched.browser = 'firefox'
    }
  }
  // If it's not mobile we should consider it's desktop platform, meaning it runs a desktop browser
  // It's a workaround for anonymized user agents
  // (browser.cros || browser.mac || browser.linux || browser.win)
  else {
    browser.desktop = true
  }

  // Set iOS if on iPod, iPad or iPhone
  if (browser.ipod || browser.ipad || browser.iphone) {
    browser.ios = true
  }

  if (browser['windows phone']) {
    browser.winphone = true
    delete browser['windows phone']
  }

  // The assumption about WebKit based browsers below is not completely accurate.
  // Google released Blink(a fork of WebKit) engine on April 3, 2013, which is really different than WebKit today.
  // Today, one might want to check for WebKit to deal with its bugs, which is used on all browsers on iOS, and Safari browser on all platforms.

  // Chrome, Opera 15+, Vivaldi and Safari are webkit based browsers
  if (
    browser.chrome ||
    browser.opr ||
    browser.safari ||
    browser.vivaldi ||
    // we expect unknown, non iOS mobile browsers to be webkit based
    (browser.mobile === true && browser.ios !== true && knownMobiles !== true)
  ) {
    browser.webkit = true
  }

  // (Qv3) rename the terms 'edge' to 'edge legacy'(or remove it) then 'edge chromium' to 'edge' to match with the known up-to-date terms
  // Microsoft Edge is the new Chromium-based browser. Microsoft Edge Legacy is the old EdgeHTML-based browser (EOL: March 9, 2021).
  if (browser.edg) {
    matched.browser = 'edgechromium'
    browser.edgeChromium = true
  }

  // Blackberry browsers are marked as Safari on BlackBerry
  if ((browser.safari && browser.blackberry) || browser.bb) {
    matched.browser = 'blackberry'
    browser.blackberry = true
  }

  // Playbook browsers are marked as Safari on Playbook
  if (browser.safari && browser.playbook) {
    matched.browser = 'playbook'
    browser.playbook = true
  }

  // Opera 15+ are identified as opr
  if (browser.opr) {
    matched.browser = 'opera'
    browser.opera = true
  }

  // Stock Android browsers are marked as Safari on Android.
  if (browser.safari && browser.android) {
    matched.browser = 'android'
    browser.android = true
  }

  // Kindle browsers are marked as Safari on Kindle
  if (browser.safari && browser.kindle) {
    matched.browser = 'kindle'
    browser.kindle = true
  }

  // Kindle Silk browsers are marked as Safari on Kindle
  if (browser.safari && browser.silk) {
    matched.browser = 'silk'
    browser.silk = true
  }

  if (browser.vivaldi) {
    matched.browser = 'vivaldi'
    browser.vivaldi = true
  }

  // Assign the name and platform variable
  browser.name = matched.browser
  browser.platform = matched.platform

  if (process.client) {
    if (userAgent.indexOf('electron') > -1) {
      browser.electron = true
    } else if (document.location.href.indexOf('-extension://') > -1) {
      browser.bex = true
    } else {
      if (window.Capacitor !== void 0) {
        browser.capacitor = true
        browser.nativeMobile = true
        browser.nativeMobileWrapper = 'capacitor'
      } else if (
        window._cordovaNative !== void 0 ||
        window.cordova !== void 0
      ) {
        browser.cordova = true
        browser.nativeMobile = true
        browser.nativeMobileWrapper = 'cordova'
      }

      if (
        hasTouch === true &&
        browser.mac === true &&
        ((browser.desktop === true && browser.safari === true) ||
          (browser.nativeMobile === true &&
            browser.android !== true &&
            browser.ios !== true &&
            browser.ipad !== true))
      ) {
        /*
         * Correction needed for iOS since the default
         * setting on iPad is to request desktop view; if we have
         * touch support and the user agent says it's a
         * desktop, we infer that it's an iPhone/iPad with desktop view
         * so we must fix the false positives
         */
        applyIosCorrection(browser)
      }
    }
  }

  return browser
}

export const client: IPlatform = {
  userAgent,
  is: getPlatform(userAgent),
  has: {
    touch: process.client ? hasTouch : false,
    webStorage: process.client
  },
  within: {
    iframe: process.client ? window.self !== window.top : false
  }
}

const Platform = {
  install(app: App) {
    const globalProperties = app.config.globalProperties

    globalProperties.$patform = client
  }
}

export default Platform
