<template>
  <Teleport to="body">
    <TransitionGroup
      tag="div"
      name="pos--top"
      class="notifications"
    >
      <div
        v-for="item in notifyArr"
        class="notification"
        :class="`is-${item.type}`"
        :key="item.id"
      >
        <AppButton
          class="right-2 top-2 absolute"
          icon
          flat
          round
          size="small"
          prefix-icon="com:close-small"
          @click="onClickCloseBtn(item)"
        />
        <div class="notification-content">
          <div class="flex flex-row gap-3 md:gap-4">
            <div>
              <AppIcon
                :name="'com:' + item.type"
                class="w-4 h-4 align-middle"
                :class="`is-${item.type}`"
              />
            </div>
            <div v-purify="item.text"></div>
          </div>
          <div
            v-if="item.handlerClick"
            class="pt-4 pl-8"
          >
            <AppButton
              view="outline"
              :text="item.btnText"
              @click="onActionBtnClick(item)"
            />
          </div>
        </div>
      </div>
    </TransitionGroup>
  </Teleport>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import AppIcon from '../components/AppIcon.vue'
import type { INotify } from '../models/Notify'
import { nanoid } from 'nanoid'
import AppButton from '../components/AppButton.vue'

export default defineComponent({
  name: 'AppNotifications',
  components: { AppButton, AppIcon },
  setup() {
    const notifyArr = ref<INotify[]>([])

    function getNotifyById(id: string) {
      return notifyArr.value.findIndex(notify => notify.id === id)
    }

    function deleteNotify(notifyId?: string) {
      if (!notifyId) {
        return
      }

      notifyArr.value.splice(getNotifyById(notifyId), 1)
    }

    function showNotify(args: INotify) {
      const notifyId = nanoid()

      let timerHandle = undefined

      if (args.timeout !== Infinity) {
        timerHandle = setTimeout(() => {
          deleteNotify(notifyId)
        }, args.timeout || 5000)
      }

      notifyArr.value.push({ ...args, timerHandle, id: notifyId })
    }

    function error(args: INotify) {
      showNotify({
        ...args,
        type: 'error'
      })
    }

    function success(args: INotify) {
      showNotify({
        ...args,
        type: 'success'
      })
    }

    function info(args: INotify) {
      showNotify({
        ...args,
        type: 'info'
      })
    }

    function onClickCloseBtn(notify: INotify) {
      clearTimeout(notify.timerHandle)
      deleteNotify(notify?.id)
    }

    function onActionBtnClick(notify: INotify) {
      if (typeof notify.handlerClick === 'function') {
        notify.handlerClick()

        if (notify.closeOnClick) {
          onClickCloseBtn(notify)
        }
      }
    }

    return {
      notifyArr,
      error,
      success,
      info,
      onClickCloseBtn,
      onActionBtnClick
    }
  }
})
</script>
