import VueGtag from 'vue-gtag'
import type { Router } from 'vue-router'
import type { Plugin } from 'vue'

const mode = import.meta.env.MODE
const enabled = mode === 'stage' || mode === 'production'

export default (router: Router) =>
  [
    VueGtag,
    {
      enabled,
      config: {
        id: import.meta.env.PUBLIC_GA_MEASUREMENT_ID,
        params: {
          send_page_view: true,
          debug_mode: mode === 'stage'
        }
      }
    },
    router
  ] as [Plugin, object, Router]
