/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { RouteLocationNormalized } from 'vue-router'
import { useAuthStore } from '~common/stores/auth'

import { useI18n } from '~common/composables/useI18n'
import { useReferral } from '~common/composables/useReferral'

import useNotAuthCookies from '~/composables/useNotAuthCookies'
// @ts-ignore
import redirectsConfig from './redirects.json'

interface TRedirect {
  dest: string
  code?: number
}

export async function beforeEach(to: RouteLocationNormalized) {
  // @ts-ignore
  const rule: TRedirect | undefined = redirectsConfig[to.path]

  const { isAuthenticated, isLoadingFirstData, user } = useAuthStore()
  const { setLocale } = useI18n()

  if (import.meta.env.MODE !== 'development') {
    if (user.is_maintenance && to.redirectedFrom?.path !== '/technical-works') {
      return '/technical-works'
    }
  }

  if (rule !== undefined) {
    return rule.dest
  }

  if (to.query?.ref) {
    useReferral().setCode(to.query.ref as string)

    return {
      ...to,
      query: { ...to.query, ref: undefined }
    }
  }

  if (to.query?.lang) {
    setLocale(to.query.lang as string).then()

    return {
      ...to,
      query: { ...to.query, lang: undefined }
    }
  }

  if (
    !isAuthenticated &&
    to.meta.redirectNoAuth &&
    typeof to.meta.redirectNoAuth === 'string'
  ) {
    return {
      name: to.meta.redirectNoAuth,
      params: to.params
    }
  }

  if (to.meta.public) {
    return
  }

  if (to.meta.layout && to.meta.layout === 'auth' && isAuthenticated) {
    return '/'
  }

  if (!isAuthenticated && !isLoadingFirstData) {
    const notAuthPath = `/auth/${
      to.query?.external ? 'register' : 'login'
    }?url=${to.path}`

    if (to.query) {
      useNotAuthCookies().setData(to.query as Record<string, string>)
    }

    return notAuthPath
  }
}
