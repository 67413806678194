import { httpClient } from '../plugins/httpClient'

import type {
  ICurrencyAdmin,
  ICurrencyCommon,
  ICurrency
} from '../models/Currency'

import type { IPaginationPayload } from '../models/Pagination'

export interface IFetchPublicCurrenciesParams extends IPaginationPayload {
  is_deposit_enabled?: boolean
}

export interface IUpdateCurrencyBlockchainFields {
  is_default: boolean
  is_deposit_enabled: boolean
  is_withdrawal_enabled: boolean
  sort: number
  withdrawal_fee_abs: number
  min_withdrawal_amount: number
  min_deposit_amount: number
  domain?: string
  is_show_invoice: boolean
}

export interface IUpdateCurrencyBlockchainPayload {
  pk: number
  currency_symbol: string
  fields: IUpdateCurrencyBlockchainFields
}

export interface IFetchAdminCurrenciesParams {
  domain?: string
}

export interface IUpdateCurrencyPayload {
  currency: Partial<ICurrency<ICurrencyAdmin>>
  params: { domain?: string }
}

export default {
  async fetchPublicCurrencies(params?: IFetchPublicCurrenciesParams) {
    const result = await httpClient.get<ICurrencyCommon[]>(
      '/public/currencies',
      { params }
    )

    return result.data
  },

  async fetchUserCurrencies() {
    const result = await httpClient.get<ICurrencyCommon[]>('/currencies')

    return result.data
  },

  async fetchAdminCurrencies(params?: IFetchAdminCurrenciesParams) {
    const result = await httpClient.get<ICurrencyCommon<ICurrencyAdmin>[]>(
      '/admin/currencies',
      { params }
    )

    return result.data
  },

  async updateCurrency({ currency, params }: IUpdateCurrencyPayload) {
    const result = await httpClient.put(
      `/admin/currencies/${currency.id}`,
      {
        ...currency,
        symbol: undefined,
        id: undefined,
        blockchain_networks: undefined
      },
      { params }
    )

    return result.data
  },

  async updateCurrencyBlockchain(payload: IUpdateCurrencyBlockchainPayload) {
    const result = await httpClient.put(
      `/admin/currency-blockchain/${payload.pk}`,
      { ...payload.fields, currency_symbol: payload.currency_symbol }
    )

    return result.data
  }
}
