import { watch } from 'vue'
import { useAuthStore } from '../stores/auth'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { beforeEach } from '../router/guard'

export default () => {
  const store = useAuthStore()
  const router = useRouter()
  const route = useRoute()

  const { isLoadingFirstData, isAuthenticated } = storeToRefs(store)

  watch([isAuthenticated, isLoadingFirstData], async () => {
    await router.isReady()

    const path = await beforeEach(route)

    if (path === undefined) {
      return
    }

    router.push(path).then()
  })
}
