import { httpClient } from '../plugins/httpClient'

import type { IWLUISettings } from '../models/WhiteLabel'

export default {
  async fetchSettings(domain?: string) {
    const response = await httpClient.get<{ ui_settings: IWLUISettings }>(
      '/public/ui-settings',
      { params: { domain } }
    )

    return response.data.ui_settings
  }
}
