import { client } from './platform'
import { SHA } from '../utils/strings'

const canvas = document.createElement('canvas')
const gl = canvas.getContext('webgl')

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
const timezone_offset = new Date().getTimezoneOffset()

let graphics_card = ''

if (gl !== null) {
  graphics_card = gl.getParameter(gl.RENDERER)
}

let id = ''

window.fingerprint = {
  async getVisitorId() {
    if (!id) {
      id = await SHA(
        window.screen.availWidth +
          'x' +
          window.screen.availHeight +
          '_' +
          window.screen.colorDepth +
          '_' +
          navigator.language +
          '_' +
          navigator.cookieEnabled +
          '_' +
          timezone +
          '_' +
          timezone_offset +
          '_' +
          graphics_card +
          '_' +
          client.has.touch +
          '_' +
          client.is.name +
          '_' +
          client.is.platform +
          '_' +
          client.is.mobile
      )
    }

    return id
  }
}
