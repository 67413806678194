<template>
  <div>
    <component
      :is="modal.component"
      v-for="modal in modals.items"
      :key="modal.name"
      v-bind="modal.compAttrs || {}"
      :show="modal.show"
      v-on="modal.compListeners || {}"
      @close="handleRemove(modal)"
    />
  </div>
</template>

<script setup lang="ts">
import { useModals } from '../composables/useModals'

interface IModalEvent {
  show: boolean
  name: string
}

/* ------------------------------------------------------------------------------------------------------------------ */
const modals = useModals()

const handleRemove = (event: IModalEvent) => {
  event.show = false

  setTimeout(() => {
    modals.remove(event.name)
  }, 500)
}
</script>
