import { watch } from 'vue'
import useNotify from '../composables/notify/useNotify'
import { useI18n } from '../composables/useI18n'
// @ts-ignore
import { useRegisterSW } from 'virtual:pwa-register/vue'

export default (messageTextKey: string, btnTitleKey: string) => {
  const { needRefresh, updateServiceWorker } = useRegisterSW()
  const notify = useNotify()
  const { t } = useI18n()

  watch(needRefresh, () => {
    notify.notifySuccess(t(messageTextKey), {
      btnText: t(btnTitleKey),
      timeout: Infinity,
      closeOnClick: true,
      handlerClick() {
        updateServiceWorker(true).then()
      }
    })
  })
}
