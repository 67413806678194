import { useI18n } from '~common/composables/useI18n'
import useCurrency from '~common/stores/currency'
import { useNotify } from '~common/composables/notify'

import type { IOrderSocket, IUserOrderMix } from '~common/models/Order'
import type { TCurrency } from '~common/models/Currency'

export default () => {
  const refNotification = useNotify().notify

  const currencyStore = useCurrency()
  const { t } = useI18n()

  function showOrderMessage(
    translateKey: string,
    data: IOrderSocket | IUserOrderMix
  ) {
    if (!refNotification) {
      return
    }

    const [base_currency, quote_currency] = data.market_name.split(
      '/'
    ) as TCurrency[]

    const dataMessage = {
      id: data.id,
      side: t(`app.ExchangePage.titleDialogDelete.${data.side}`),
      market_name: data.market_name,
      amount:
        currencyStore.formatQuote(data.amount, base_currency) +
        ' ' +
        base_currency,
      filled:
        currencyStore.formatQuote(
          (data.amount || 0) - (data.filled || 0),
          base_currency
        ) +
        ' ' +
        base_currency,
      price:
        currencyStore.formatQuote(
          (data.amount || 0) * (data.price || 0),
          quote_currency
        ) +
        ' ' +
        quote_currency
    }

    refNotification.value?.success({
      text: t(translateKey, dataMessage),
      timeout: 12000
    })
  }

  return {
    showOrderMessage
  }
}
