import { useCookies } from '@vueuse/integrations/useCookies'

const cookieDomainName = import.meta.env.PUBLIC_COOKIE_DOMAIN
const cookies = useCookies(['notAuth'])

type NotAuthData = Record<string, string>

export default () => {
  return {
    setData: (data: NotAuthData) => {
      cookies.set('notAuth', data, {
        path: '/',
        domain: cookieDomainName,
        maxAge: 60 * 60 * 24 * 365 // 365 day
      })
    },
    getData: (): NotAuthData => {
      return cookies.get('notAuth') || {}
    },
    removeData: () => {
      cookies.remove('notAuth')
    }
  }
}
