import { httpClient } from '~common/plugins/httpClient'
import type {
  IUserAPIKey,
  IUserCommunication,
  IUserResponce,
  TUserBehaviorPage
} from '~/models/UserApi'
import type { IResponseResult } from '~/common/src/models/Api'
import type { IAuthUser } from '~/common/src/models/AuthUser'
import type { IUserTask } from '~/models/User'

export default {
  async getKeys() {
    const result = await httpClient.get<IUserAPIKey[]>('/user/api_keys')

    return result.data
  },

  async generateKey(invoices_webhook = '') {
    const result = await httpClient.post<IUserAPIKey>('/user/api_keys', {
      invoices_webhook
    })

    return result.data
  },

  async updateWebhook(id: string, invoices_webhook = '') {
    await httpClient.patch('/user/api_keys', {
      id,
      invoices_webhook
    })
  },

  async deleteKey(id: string) {
    await httpClient.delete(`/user/api_keys?id=${id}`)
  },

  async updateCommunicationChannels(payload: IUserCommunication) {
    return await httpClient.patch<IUserResponce>(
      '/user/communication-channels',
      payload
    )
  },

  async clickUserBehaviorPage(pageName: TUserBehaviorPage) {
    return await httpClient.post<IResponseResult>(
      `/user/behaviour/${pageName}/click`
    )
  },

  async fetchMainUser() {
    const result = await httpClient.get<IAuthUser>('/user/main-user')

    return result.data
  },

  async fetchUserTasks() {
    const result = await httpClient.get<IUserTask[]>('/user/tasks')

    return result.data
  }
}
