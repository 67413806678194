import '~common/plugins/fingerprint'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import i18nPlugin from '~common/plugins/i18n'
import gtag from '~common/plugins/gtag'
import yandexMetrikaPlugin from '~common/plugins/yandex-metrika'
import platform from '~common/plugins/platform'
import screenPlugin from '~common/plugins/screen'
import httpClientPlugin from '~common/plugins/httpClient'
import websocketPlugin from '~common/plugins/websocket'
import { createHead } from '@unhead/vue'

import '~common/plugins/vee-validate'

import purifyDirective from '~common/directives/purify'

import { sentryInit } from '~common/plugins/sentry'

import App from './App.vue'
import router from './router'

import '~/common/shared/index.css'
import '~/assets/styles/index.css'

import { useI18n } from '~common/composables/useI18n'

async function init() {
  const pinia = createPinia()

  const app = createApp(App)

  const head = createHead()

  sentryInit(app, router)

  app.use(platform)
  app.use(screenPlugin)
  app.use(pinia)
  app.use(i18nPlugin)
  app.use(purifyDirective)
  app.use(...gtag(router))
  app.use(...yandexMetrikaPlugin(router))
  app.use(httpClientPlugin, { baseURL: import.meta.env.PUBLIC_API_URL })
  app.use(websocketPlugin, { url: import.meta.env.PUBLIC_WEBSOCKET_URL })

  const i18n = useI18n()

  await i18n.setLocale()

  app.use(router)
  app.use(head)

  app.mount('#app')
}

init().then()
