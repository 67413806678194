import BASE_COLORS_THEME from './baseColorsTheme'
import type { ITheme } from './models'

export const hexToRGB = (hex?: string) => {
  if (!hex) return ''

  return [
    parseInt(hex.slice(1, 3), 16),
    parseInt(hex.slice(3, 5), 16),
    parseInt(hex.slice(5, 7), 16)
  ].join(', ')
}

export const mapTheme: (variables: ITheme) => ITheme = (variables: ITheme) => {
  return Object.entries(variables).reduce(
    (acc, [key, val]) => ({ ...acc, [`--color-${key}`]: hexToRGB(val) }),
    {}
  )
}

export const extendTheme = (extending: ITheme, newTheme: ITheme) => {
  return { ...extending, ...newTheme }
}

export const applyTheme = (theme: ITheme) => {
  const mapedTheme = mapTheme(theme)

  if (!mapedTheme || !document) return

  const root = document.documentElement

  Object.keys(mapedTheme).forEach(property => {
    if (property === 'name') return

    root.style.setProperty(property, mapedTheme[property])
  })
}

export const initTheme = () => {
  applyTheme(BASE_COLORS_THEME)
}
