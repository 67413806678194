import { storeToRefs } from 'pinia'
import { watch } from 'vue'
import useUiSettingsStore from '~/common/src/stores/uiSettings'
import { initTheme, applyTheme } from '~/common/themes/utils'

export default () => {
  const { uiSettings } = storeToRefs(useUiSettingsStore())

  watch(uiSettings, val => {
    if (!val?.theme) return

    applyTheme(val.theme)
  })

  initTheme()
}
