import { ref } from 'vue'

import type { INotifyComponent } from '../../models/Notify'

export const notification = ref<INotifyComponent>()

export default () => {
  return {
    notification
  }
}
