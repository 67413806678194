import { onMounted, onUnmounted } from 'vue'

export function useEventListener(
  target: HTMLElement | Document | Window,
  event: string,
  callback: (event: Event) => void,
  options?: object
): void {
  onMounted(() => target.addEventListener(event, callback, options))
  onUnmounted(() => target.removeEventListener(event, callback, options))
}

export function useBeforeUnload(callback: (event?: BeforeUnloadEvent) => void) {
  useEventListener(
    window,
    'beforeunload',
    function (event: Event) {
      event.preventDefault()
      callback(event)
    },
    { capture: true }
  )

  onUnmounted(callback)
}
