import { type App, reactive } from 'vue'
import debounce from '../utils/debounce'
import { client } from './platform'

export type IScreen = typeof screen

export const screen = reactive({
  width: 0,
  height: 0,
  name: 'xs',

  // При изменении sizes, посмотри в "src/common/tailwind.config.js"
  sizes: {
    xs: 480,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    '2xl': 1536
  },

  // lower than (ниже чем)
  lt: {
    sm: true,
    md: true,
    lg: true,
    xl: true,
    '2xl': true
  },

  // greater than (больше чем)
  gt: {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false
  },

  xs: true,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  '2xl': false
})

const Screen = {
  install(app: App) {
    if (process.client) {
      const { visualViewport } = window
      const target = visualViewport || window

      const scrollingElement =
        document.scrollingElement || document.documentElement

      const getSize =
        visualViewport === void 0 || visualViewport === null || client.is.mobile
          ? () => [
              Math.max(window.innerWidth, scrollingElement.clientWidth),
              Math.max(window.innerHeight, scrollingElement.clientHeight)
            ]
          : () => [
              visualViewport.width * visualViewport.scale +
                window.innerWidth -
                scrollingElement.clientWidth,
              visualViewport.height * visualViewport.scale +
                window.innerHeight -
                scrollingElement.clientHeight
            ]

      function __update() {
        const [w, h] = getSize()

        if (h !== screen.height) {
          screen.height = h
        }

        if (w !== screen.width) {
          screen.width = w
        }

        const s = screen.sizes

        screen.gt.xs = w >= s.sm
        screen.gt.sm = w >= s.md
        screen.gt.md = w >= s.lg
        screen.gt.lg = w >= s.xl
        screen.gt.xl = w >= s['2xl']
        screen.lt.sm = w < s.sm
        screen.lt.md = w < s.md
        screen.lt.lg = w < s.lg
        screen.lt.xl = w < s.xl
        screen.lt['2xl'] = w < s['2xl']
        screen.xs = screen.lt.sm
        screen.sm = screen.gt.xs && screen.lt.md
        screen.md = screen.gt.sm && screen.lt.lg
        screen.lg = screen.gt.md && screen.lt.xl
        screen.xl = screen.gt.lg && screen.lt['2xl']
        screen['2xl'] = screen.gt.xl

        const ss =
          (screen.xs && 'xs') ||
          (screen.sm && 'sm') ||
          (screen.md && 'md') ||
          (screen.lg && 'lg') ||
          (screen.xl && 'xl') ||
          '2xl'

        if (ss !== screen.name) {
          screen.name = ss
        }
      }

      __update()

      const updateEvt = debounce(__update, 100)

      target.addEventListener('resize', updateEvt, { passive: true })
    }

    const globalProperties = app.config.globalProperties

    globalProperties.$screen = screen
  }
}

export default Screen
