import type { App, DirectiveBinding } from 'vue'
import dompurify from 'dompurify'

const instance = dompurify()

const SANITIZE_CONFIG = {
  ADD_ATTR: ['target']
}

export default {
  install(app: App) {
    app.directive('purify', {
      created(el: HTMLElement, binding: DirectiveBinding<HTMLElement>) {
        el.innerHTML = instance.sanitize(binding.value, SANITIZE_CONFIG)
      },
      updated(el: HTMLElement, binding: DirectiveBinding<HTMLElement>) {
        el.innerHTML = instance.sanitize(binding.value, SANITIZE_CONFIG)
      }
    })
  }
}
