import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { IWLUISettings } from '../models/WhiteLabel'
import uiSettingsApi from '../api/uiSettings.api'

export default defineStore('ui-settings', () => {
  const uiSettings = ref<IWLUISettings>()

  const fetchUISettings = async (domain?: string) => {
    const res = await uiSettingsApi.fetchSettings(domain)

    uiSettings.value = res
  }

  return {
    uiSettings,
    fetchUISettings
  }
})
