import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { IUserTask, TUserTaskOperationType } from '~/models/User'
import userApi from '~/api/userApi.api'
import useWatchAuthenticated from '~/common/src/composables/useWatchAuthenticated'

export const useTasksStore = defineStore('tasks', () => {
  const tasks = ref<IUserTask[]>([])

  const fetchUserTasks = async () => {
    tasks.value = await userApi.fetchUserTasks()
  }

  const getOperationTasksCount = (type: TUserTaskOperationType) => {
    return tasks.value.find(({ operation_type }) => operation_type === type)
      ?.amount
  }

  useWatchAuthenticated(fetchUserTasks)

  return {
    tasks,
    getOperationTasksCount
  }
})
