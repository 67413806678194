import { createI18n } from 'vue-i18n'

function russianPluralization(choice: number, length: number) {
  if (choice === 0) {
    return 0
  }

  const teen = choice > 10 && choice < 20
  const endsWithOne = choice % 10 === 1

  if (!teen && endsWithOne) {
    return 1
  }

  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2
  }

  return length < 4 ? 2 : 3
}

function logPluralization(choice: number, length: number) {
  const rp = russianPluralization(choice, length)

  //console.log(rp)

  return rp
}

const i18n = createI18n({
  globalInjection: true,
  legacy: false,
  locale: 'none',
  pluralRules: {
    ru: logPluralization
  },
  fallbackLocale: 'en',
  missingWarn: false,
  fallbackWarn: false,
  warnHtmlMessage: false
})

export default i18n
