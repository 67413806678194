import { httpClient } from '../plugins/httpClient'

import type {
  IAuthLoginForm,
  IAuthRegisterForm,
  IAuthUser,
  IAuthUserBalanceBase,
  IAdminPermissions,
  IRestorePassword,
  ISessionResponse,
  I2faKeys,
  IUserVideoGuide,
  IUISettings,
  IUpdateUserPayload
} from '../models/AuthUser'

interface IFetchUserVideoGuidesResponse {
  items: IUserVideoGuide[]
}

interface IUserLogoutPayload {
  access_token: string
}

interface IUserChangePasswordPayload {
  password: string
  access_token: string
}

interface IUiSettingsResponse {
  settings: IUISettings
}

export default {
  async login(params: IAuthLoginForm) {
    const result = await httpClient.post<ISessionResponse>(
      '/public/user/authorization',
      params
    )

    return result.data
  },

  async logout(payload: IUserLogoutPayload) {
    const result = await httpClient.post<Record<string, unknown>>(
      '/user/logout',
      payload
    )

    return result.data
  },

  async register(params: IAuthRegisterForm) {
    const result = await httpClient.post<ISessionResponse>(
      '/public/user/registration',
      params
    )

    return result.data
  },

  async refreshToken(refreshToken: string | undefined) {
    const result = await httpClient.post<ISessionResponse>(
      '/user/refresh_token',
      { refresh_token: refreshToken }
    )

    return result.data
  },

  async fetchUser() {
    const result = await httpClient.get<IAuthUser>('/user/current-user')

    return result.data
  },

  async fetchUserBalances() {
    const result = await httpClient.get<IAuthUserBalanceBase[]>('/balances')

    // Добавляем этот фильтр именно в апи, потому что нам в принципе
    // во всем приложении нигде не нужны больше бонусные рубли
    // если уберут их с бека, то фильтр можно будет удалить
    return result.data?.filter(b => b.symbol !== 'RUBB')
  },

  async fetchUserVideoGuides() {
    const result =
      await httpClient.get<IFetchUserVideoGuidesResponse>('/user/video-guides')

    return result.data
  },

  async updateUserVideoGuide(payload: IUserVideoGuide) {
    const result = await httpClient.post<Record<string, string>>(
      '/user/video-guides',
      payload
    )

    return result.data
  },

  async fetchAdminPermissions() {
    const result = await httpClient.get<IAdminPermissions>(
      '/admin/users/admin/permissions'
    )

    return result.data
  },

  async updateUser(payload: IUpdateUserPayload) {
    const result = await httpClient.post<IAuthUser>(
      '/user/current-user',
      payload
    )

    return result.data
  },

  async confirmEmail(payload: { email: string; code: string }) {
    const { email, code } = payload

    const result = await httpClient.post('/public/user/confirm-email', {
      username: email,
      code: code.replace(/\D/g, '').trim()
    })

    return result.data
  },

  async resendConfirmEmail(email: string) {
    const result = await httpClient.post(
      '/public/user/send-confirmation-email',
      {
        email
      }
    )

    return result.data
  },

  async changePassword(payload: IUserChangePasswordPayload) {
    const result = await httpClient.post('/user/password/change', payload)

    return result.data
  },

  async forgotPassword(email: string) {
    const result = await httpClient.get('/public/user/password/forgot', {
      params: { email }
    })

    return result.data
  },

  async restorePassword(payload: IRestorePassword) {
    const result = await httpClient.post(
      '/public/user/password/forgot',
      payload
    )

    return result.data
  },

  async setLocale(lang: string) {
    const result = await httpClient.post('/user/current-user', {
      locale: lang
    })

    return result.data
  },

  async getTwoFactorAuthKeys() {
    const response = await httpClient.get<I2faKeys>('/user/2fa')

    return response.data
  },

  async toggleTwoFactorAuth(enabled = true, code?: string) {
    await httpClient.post('/user/2fa', { enabled, code })

    return enabled
  },

  async saveUiSettings(payload: IUISettings) {
    const { data } = await httpClient.put<IUiSettingsResponse>(
      '/user/current-user/ui-settings',
      { settings: payload }
    )

    return data.settings
  }
}
