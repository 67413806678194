import { computed } from 'vue'
import { useCookies } from '@vueuse/integrations/useCookies'

const cookieDomainName = import.meta.env.PUBLIC_COOKIE_DOMAIN
const cookies = useCookies(['p2pUnreadDeals'])

const setUnreadDealsCookies = (value: number[]) => {
  cookies.set('p2pUnreadDeals', value, {
    path: '/',
    domain: cookieDomainName,
    maxAge: 60 * 60 * 24 * 365 // 365 day
  })
}

const removeUnreadDealsCookies = () => {
  cookies.remove('p2pUnreadDeals')
}

export default () => {
  const getUnreadDealsIds = (): number[] => {
    const cookieValue = cookies.get('p2pUnreadDeals')

    return Array.isArray(cookieValue) ? cookieValue : []
  }

  const addUnreadDealId = (id: number) => {
    setUnreadDealsCookies([...getUnreadDealsIds(), id])
  }

  const removeUnreadDealId = (dealId: number) => {
    const filteredDeals = getUnreadDealsIds().filter(id => id !== dealId)

    setUnreadDealsCookies(filteredDeals)
  }

  const uniqUnreadDealsIds = computed(() => {
    return [...new Set(getUnreadDealsIds())]
  })

  const isUnreadDeals = computed(() => !!uniqUnreadDealsIds.value.length)

  const isUnreadDeal = (id: number) => {
    return uniqUnreadDealsIds.value.includes(id)
  }

  return {
    addUnreadDealId,
    removeUnreadDealId,
    uniqUnreadDealsIds,
    isUnreadDeals,
    isUnreadDeal,
    removeUnreadDealsCookies
  }
}
